import React from "react";
import EventList from "../components/event-list/EventList";

export default function Competition() {
  return (
    <div>
      <div className="event-list mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:p-10">
        <div className="about-title mb-4 text-lg main-subtitle">
          Event List
        </div>
        <div className='comp-sub-title mb-6 text-2xl md:text-5xl'>Enigma Ekatva Events</div>
        <EventList></EventList>
      </div>
    </div>
  );
}
