import React from "react";

export default function About() {
  return (
    <div className="showcase-us mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:p-10">
      <div className="main-subtitle mb-4 text-lg">R.A. Podar College</div>
      <h6 className="mb-5 text-3xl">About R.A. Podar College of Commerce and Economoics ( Empowered Autonomous)</h6>


      <p>
        R.A. Podar College of Commerce & Economics, Mumbai which started with a
        batch of just 150 students, has grown successfully over a period of 8
        decades and currently stands proud with accreditation of ‘A+’ grade by
        NAAC. We believe in “Nurturing Intellect and Creating Personalities”.
        The learners of Podar have successfully proved their prowess in myriad
        avenues which is a testament to the statement- “Podarites are born for
        Greater Things.” Our college was graced by the presence of Hon. Vice
        President of India, Shri. Venkiah Naidu, on the occasion of its Platinum
        Jubilee Celebration.
      </p>

      <p className="mt-5">
        ENIGMA is one of the biggest cultural festivals of Mumbai, organized by
        R.A.Podar College of Commerce and Economics. It takes you on a 3-day
        journey of energy, excitement & exuberance that brings together some of
        the finest talents under a single roof. The festival is the meeting of
        cultural minds and is designed in such a way that it helps students
        carve a niche for themselves and experiment with their abilities. Be it
        a dancer, actor, painter or singer, we welcome everyone to our humble
        abode.
      </p>

      <h4 className="text-bold md:mt-6 md:text-2xl">ENIGMA – UNVEIL THE MYSTERIES, FULFILL YOUR JORNEY
      </h4>
    </div>
  );
}
