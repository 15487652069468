import React, { useState, useEffect } from 'react';
import "./results.scss"


const Results = () => {
  const [sheetData, setSheetData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const API_KEY = 'YOUR_API_KEY'; // Replace with your Google Cloud API Key
    const SPREADSHEET_ID = 'YOUR_SPREADSHEET_ID'; // Replace with your Google Sheet ID

    const apiUrl = `https://sheets.googleapis.com/v4/spreadsheets/1gII0Z1n07vQFuf5-ORFSiCrUYXgCNOxup134MwMJUAk/values/Sheet1?key=AIzaSyBfsz8BjzV1nDD3LhrUkpUqgg_ppE2MHS8`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        const sheetData = data.values;
        setSheetData(sheetData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }, []); // Empty dependency array ensures the effect runs only once

  const sortedSheetData = [...sheetData].sort((a, b) => {
    // Assuming the response value is in the second column (index 1)
    const aValue = parseInt(a[1]); // Parse to integer if it's a number
    const bValue = parseInt(b[1]);
  
    return bValue - aValue; // Sort in descending order
  });
  

  return (
    <div className='results'>
      {error ? (
        <p>Error fetching data.</p>
      ) : sheetData.length > 0 ? (
        <table className='data-table'>
        {sortedSheetData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className={`cell-${rowIndex}`}>{cell}</td>
            ))}
          </tr>
        ))}
      </table>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default Results;
