import React from 'react'
import Counter from '../components/counter/Counter';
import Banner from '../components/header-banner/Banner';
import ImageGrid from '../components/img-grid/ImageGrid';
import { AnimationOnScroll } from 'react-animation-on-scroll';


import aboutImg from "../assets/images/main.jpg"
import { Link } from 'react-router-dom';

export default function Home() {

	return (
		<div>

			<Banner />
			<div className='about-us mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 md:p-10'>
				<div className='md:flex'>
					<div className='md:w-1/2'>
						<div className='about-title mb-4 text-lg main-subtitle'>About Us</div>
						<div className='about-sub-title mb-6 text-5xl'><AnimationOnScroll animateIn="animate__fadeInLeft">ENIGMA EKATVA</AnimationOnScroll></div>
						<div className='text-lg text-slate-500 mb-3'><span>ENIGMA</span> is one of the biggest cultural festivals of Mumbai, organized by R.A.Podar College of
							Commerce and Economics. It takes you on a 3-day journey of energy, excitement & exuberance that brings together
							some of the finest talents under a single roof. The festival is the meeting of cultural minds and is designed in such a
							way that it helps students carve a niche for themselves and experiment with their abilities. Be it a dancer,
							actor, painter or singer, we welcome everyone to our humble abode.
						</div>
						<div className='reg-button main-subtitle'>
							<Link to="/competition">Click here to register for Competition</Link>
						</div>
					</div>
					<div className='promo-creative pb-10 pt-10 sm:pt-0 sm:pb-0 md:w-1/2 md:pl-16'>
						<AnimationOnScroll animateIn="animate__fadeInRight"> <img src={aboutImg} /></AnimationOnScroll>
					</div>
				</div>
			</div>
			<Counter />
			<ImageGrid />
		</div>
	)
}
