import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./EventList.scss";

import pdf from "../../assets/pdf/pdf.pdf";
import handup from "../../assets/images/events/hand-up.png";
import hourglass from "../../assets/images/events/hourglass.png";
import people from "../../assets/images/events/people.png";
import barchart from "../../assets/images/events/bar-chart.png";
import dance1 from "../../assets/images/events/dance1.png";
import dance2 from "../../assets/images/events/dance2.png";
import dance3 from "../../assets/images/events/dance3.png";
import dance4 from "../../assets/images/events/dance4.png";
import music1 from "../../assets/images/events/music/1.png";
import music2 from "../../assets/images/events/music/2.png";
import music3 from "../../assets/images/events/music/3.png";
import music5 from "../../assets/images/events/music/5.png";

import drama1 from "../../assets/images/events/drama/drama1.png";
import drama2 from "../../assets/images/events/drama/drama2.png";
import drama3 from "../../assets/images/events/drama/drama3.png";
import fine1 from "../../assets/images/events/finearts/1.png";
import fine2 from "../../assets/images/events/finearts/2.png";
import fine3 from "../../assets/images/events/finearts/3.png";
import fine4 from "../../assets/images/events/finearts/4.png";
import literary1 from "../../assets/images/events/literaryarts/1.png";
import literary2 from "../../assets/images/events/literaryarts/2.png";
import literary3 from "../../assets/images/events/literaryarts/3.png";
import literary4 from "../../assets/images/events/literaryarts/4.png";

import sports1 from "../../assets/images/events/sports/1.png";
import sports2 from "../../assets/images/events/sports/2.png";
import sports3 from "../../assets/images/events/sports/3.png";
import sports4 from "../../assets/images/events/sports/4.png";
import sports5 from "../../assets/images/events/sports/5.png";
import sports6 from "../../assets/images/events/sports/6.png";

import gaming1 from "../../assets/images/events/gaming/1.png";
import gaming2 from "../../assets/images/events/gaming/2.png";
import gaming3 from "../../assets/images/events/gaming/3.png";
// import gaming4 from "../../assets/images/events/gaming/4.png";

import busines1 from "../../assets/images/events/business/1.png";
import busines2 from "../../assets/images/events/business/2.png";
import busines3 from "../../assets/images/events/business/3.png";

import quiz1 from "../../assets/images/events/quiz/1.png";
import quiz2 from "../../assets/images/events/quiz/2.png";

import photo1 from "../../assets/images/events/photo/1.png";
import photo2 from "../../assets/images/events/photo/2.png";
import photo3 from "../../assets/images/events/photo/3.png";

import informals1 from "../../assets/images/events/informals/1.png";
import informals2 from "../../assets/images/events/informals/2.png";
import informals3 from "../../assets/images/events/informals/3.png";
import informals4 from "../../assets/images/events/informals/4.png";
import informals5 from "../../assets/images/events/informals/5.png";

import pdfLink from "../../assets/pdf/pdf.pdf";

export default function EventList() {
	return (
		<div>
			<Tabs>
				<TabList>
					<Tab>Dance</Tab>
					<Tab>Drama</Tab>
					<Tab>Sports</Tab>
					<Tab>Informals</Tab>
					<Tab>Music</Tab>
					<Tab>Fine Arts</Tab>
					<Tab>Literary arts</Tab>

					<Tab>Gaming</Tab>
					<Tab>Business Event</Tab>
					<Tab>Quizzers Arena</Tab>
					<Tab>Photography</Tab>

				</TabList>


				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">
						<div className="md:w-1/4">
							<div className="legend-grid mt-5 text-center">
								<img src={dance1}></img>
							</div>

							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/vFv1thAt8aLM24m3A"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={dance2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/hKocwbBqjWeFDbZT7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={dance3}></img>
							</div>

							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/zkg1jDGwPH8Ki4yz8"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">
						<div className="md:w-1/4">
							<div className="legend-grid mt-5 text-center">
								<img src={drama1}></img>
							</div>

							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/S4Ee6pRNiuGHeqcZ8"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={drama2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/nazpdG1GLucypLxj6"
									target="_blank"
								>
									Click here to register

								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={drama3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/3Ax4QU4VRM8ugDG97"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>


				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={sports3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/J2M5cuGF5Nckk1hv8"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={sports5}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/QkuqRFQ3vEQLRzV27"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={sports6}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/Edi3mriUMKyyFQgH8"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={informals1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/ogqSvQGMdyCz4WTn9"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={informals2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/xoh7BUeaZA9BKqsd6"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={informals3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/7cTM45qfSy5a1CaT8"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={informals4}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/3a4b5dAaxgD5Gzk5A"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={informals5}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/NJe8moB3onMqiFbo7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={music1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/TVtDzt6WL42jDknC9"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={music2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/f5q2aDFUkviPBC8h7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={music3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/T31KVWExWfFsJhiA7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={music5}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/X7k9k4HfWPXSxohC6"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={fine1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/MH3eaPNnVC9k6v27A"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={fine2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/6uTrUq6RSJfeySXE6"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={fine3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/pqDvmLVzbXSeEexr5"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={fine4}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/KijFUpp4FQ1aynH46"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={literary1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/4jXGCnofNANhV3Yy7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={literary2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/9GfcgQ6oujpmmGAk7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={literary3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/CN4GoeVh2Qu3Z9b18"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={literary4}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/jzNCAKx9ZrmKHcsL9"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={gaming1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/6EMGwGNzk5VPfY7K7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={gaming2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/V1WmSzMhmFZ2PEux7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={gaming3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/yWm34hATrM4W24KN7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>


				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={busines1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/dyyH1RxfC7cgaS1L9"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={busines2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/4TMGvvpEq6aM4kPq5"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={busines3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/jXe4wHTP9Vxu8WycA"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={quiz1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/HzfA9fdrDzKZBvRC8"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={quiz2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/bFV5oyLvVgCrMQNr6"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

				<TabPanel>
					<div className="category-list md:flex md:flex-wrap ">

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={photo1}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/gEp5ceFsYhtgKHPf9"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={photo2}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/oCCgdjv4GkwAgdYH7"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>
						<div className="md:w-1/4">
							<div className="legend-grid mt-5  text-center">
								<img src={photo3}></img>
							</div>
							<p className="">
								<a
									style={{ color: "red", textDecoration: "underline" }}
									href="https://forms.gle/ZyCVpBJiEccsocy68"
									target="_blank"
								>
									Click here to register
								</a>
							</p>
						</div>

					</div>

					<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>

					<h2 className="text-bold mt-4 mb-4 text-2xl">
						General Rules And Regulations
					</h2>
					<ul>
						<li>
							Use of inappropriate song, costume, obscenity, or vulgarity of any
							sort will not be tolerated.
						</li>
						<li>
							Plagiarism will not be tolerated. Choreography should be original.
						</li>
						<li>
							Making any controversial or political comment will lead to
							negative marking.
						</li>
						<li>
							The participants must not exceed the time limit to avoid
							disqualification.
						</li>
						<li>The decision of the Judges will be final and binding.</li>
					</ul>
				</TabPanel>

			</Tabs>

			<p className="mt-5">For complete rules and regulations please refer pdf here <a style={{ color: "red", textDecoration: "underline" }} href={pdfLink} target="_blank">Click to download</a></p>
		</div >
	);
}
