import React from 'react'
import Results from '../components/results/Result'

function Result() {
  return (
    <div>
      <div className="showcase-us mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:p-10">
        <div className="main-subtitle mb-4 text-lg">Live Score</div>
        <h6 className="mb-10 text-3xl">Enigma Ekatva</h6>
        <Results></Results>
      </div>
    </div>
  )
}

export default Result