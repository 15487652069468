import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import AppRoutes from './AppRoutes';
import Footer from './components/footer/footer';
import Socialbanner from './components/socialbanner/Socialbanner';
import 'animate.css/animate.min.css';

function App() {

  return (
    <Router>
      <div className="App">
        <Header></Header>
      </div>
      <div className="content-container">
          <AppRoutes />
      </div>
      <Socialbanner/>
    </Router>
  );
}

export default App;
