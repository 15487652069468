import React, { useState } from 'react';
import './header.scss'
import myImage from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';


const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="header-nav p-4 uppercase">
      <div className="flex items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-black font-semibold text-xl logo rotation-container">
          <Link to="/"><img className='rotate-element' src={myImage} /></Link>
        </div>
        <div className="md:hidden">
          <button
            className="text-black hover:text-gray-300 focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
        <div className="hidden md:block">
          <a
            href="#"
            className="text-black hover:text-gray-300 ml-4"
            onClick={closeMenu} // Add onClick handler to close the menu
          >
            <Link to="/"> Home </Link>
          </a>
          <a
            href="#"
            className="text-black hover:text-gray-300 ml-4"
            onClick={closeMenu} // Add onClick handler to close the menu
          >
            <Link to="/about">  About </Link>
          </a>
          <a
            href="#"
            className="text-black hover:text-gray-300 ml-4"
            onClick={closeMenu} // Add onClick handler to close the menu
          >
            <Link to="/competition">  Competition </Link>
          </a>
          <a
            href="#"
            className="text-black hover:text-gray-300 ml-4"
            onClick={closeMenu} // Add onClick handler to close the menu
          >
            <Link to="/coreteam">  Core Team </Link>
          </a>
          <a
            href="#"
            className="text-black hover:text-gray-300 ml-4"
            onClick={closeMenu} // Add onClick handler to close the menu
          >
            <Link to="/result">  Results </Link>
          </a>
          <a
            href="#"
            className="text-black hover:text-gray-300 ml-4"
            onClick={closeMenu} // Add onClick handler to close the menu
          >
            <Link to="/contactus"> Contact </Link>
          </a>
        </div>
      </div>
      <div
        className={`${isOpen ? 'block' : 'hidden'
          } md:hidden mt-4`}
      >
        <a
          href="#"
          className="text-black block mt-4 hover:text-gray-300"
          onClick={closeMenu} // Add onClick handler to close the menu
        >
          <Link to="/"> Home </Link>
        </a>
        <a
          href="#"
          className="text-black block mt-4 hover:text-gray-300"
          onClick={closeMenu} // Add onClick handler to close the menu
        >
          <Link to="/about">  About </Link>
        </a>
        <a
          href="#"
          className="text-black block mt-4 hover:text-gray-300"
          onClick={closeMenu} // Add onClick handler to close the menu
        >
          <Link to="/competition">  Competition </Link>
        </a>
        <a
          href="#"
          className="text-black block mt-4 hover:text-gray-300"
          onClick={closeMenu} // Add onClick handler to close the menu
        >
          <Link to="/coreteam">  Core Team </Link>
        </a>
        <a
          href="#"
          className="text-black block mt-4 hover:text-gray-300"
          onClick={closeMenu} // Add onClick handler to close the menu
        >
          <Link to="/contactus">  Contact us </Link>
        </a>
      </div>
    </nav>
  );
};

export default Header;
