import React from "react";

export default function Contactus() {
  return (
    <div>
      <div className="showcase-us mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:p-10">
        <div className="main-subtitle mb-4 text-lg">Contact List</div>
        <h6 className="mb-10 text-3xl">Enigma Ekatva</h6>
        <ul>
          <li className="pt-2 pb-2">
            <strong>DANCE : </strong>
            Saanvi Pawar - 8291469048
          </li>
          <li className="pt-2 pb-2">
            <strong>MUSIC : </strong>
            Janhavi - 7208572606
          </li>
          <li className="pt-2 pb-2">
            <strong>DRAMA : </strong>
            Mayur Waghe - 9322495054
          </li>
          <li className="pt-2 pb-2">
            <strong>FINE ARTS : </strong>
            Abha Khopkar - 8928561883
          </li>
          <li className="pt-2 pb-2">
            <strong>LITERARY ARTS : </strong>
            Dhruvin Amrute - 7738144049
          </li>
          <li className="pt-2 pb-2">
            <strong>SPORTS : </strong>
            Arnav Patil - 8879772267
          </li>

          <li className="pt-2 pb-2">
            <strong>GAMING : </strong>
            Dhruv Tandel - 8879223768
          </li>

          <li className="pt-2 pb-2">
            <strong>BUSINESS EVENTS : </strong>
            Aarya Patil - 9769498958
          </li>

          <li className="pt-2 pb-2">
            <strong>QUIZZERS : </strong>
            Poojan Doshi - 8591011359
          </li>
          <li className="pt-2 pb-2">
            <strong>PHOTOGRAPHY : </strong>
            Anish Kudterkar - 9819001187
          </li>

          <li className="pt-2 pb-2">
            <strong>INFORMALS : </strong>
            Kalyani Sawardekar - 8879579640
          </li>
        </ul>
      </div>
    </div>
  );
}
