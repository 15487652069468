import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import "./counter.scss";

export default function Counter() {
  const statistics = [
    { label: 'Events', value: 40 },
    { label: 'Days', value: 3 },
    { label: 'Colleges', value: 35 },
    { label: 'Participants', value: 1000 },
  ];

  return (
    <div className='scrolling-statistics-banner'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 counter-wrapper'>
        <div>
          <AnimationOnScroll animateIn="animate__fadeInLeft"><h4 class="text-3xl text-left">ENIGMA – UNVEIL THE MYSTERIES, FULFILL YOUR JORNEY
          </h4></AnimationOnScroll>
          <div className="scrolling-statistics">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-xl">
              {statistics.map((stat, index) => (
                <div className="statistic text-3xl" key={index}>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <CountUp
                        start={isVisible ? 0 : null}
                        end={stat.value}
                        duration={4}
                        separator=","
                        suffix='+'
                      />
                    )}
                  </VisibilitySensor>
                  <div className="label text-lg sm:text-3xl">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
