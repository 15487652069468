import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import CountdownTimer from "../countdown-timer/CountdownTimer";
import "../header-banner/banner.scss";

function Banner() {
	const targetDate = new Date("September 30, 2024 12:00:00 UTC");

	return (
		<div className="banner-header">
			<div class="title-container s-tb-c w-full md:w-auto">
				<div class="small-title text-2xl md:text-4xl md:mb-4 animate__animated animate__fadeInLeft">
					Announcing you the most awaited festival
				</div>
				<div class="big-title text-4xl md:text-6xl md:mb-4 animate__animated animate__infinite animate__pulse">
					Enigma Ekatva
				</div>
				<div class="title-text text-xl md:text-4xl">
					{" "}
					30th Sept, 1st & 2nd October 2024{" "}
				</div>
				<div className="countdowntimer m-4 md:m-5">
					<CountdownTimer targetDate={targetDate} />
				</div>
			</div>
		</div>
	);
}

export default Banner;
