import React from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Competition from './pages/Competition';
import Team from './pages/Team';
import Contactus from './pages/contactus';
import Result from './pages/Result';

const AppRoutes = () => (
  
  <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/competition" element={<Competition/>} />
      <Route path="/coreteam" element={<Team/>} />
      <Route path="/contactus" element={<Contactus/>} />
      <Route path="/result" element={<Result/>} />
  </Routes>
);

export default AppRoutes;
