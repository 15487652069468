import React from "react";

import image2 from "../assets/images/team/2.jpg";
import image1 from "../assets/images/team/1.jpg";
import image3 from "../assets/images/team/3.jpg";
import image5 from "../assets/images/team/5.jpg";
import image4 from "../assets/images/team/4.jpg";
import image6 from "../assets/images/team/6.jpg";
import image7 from "../assets/images/team/7.jpg";
import image8 from "../assets/images/team/8.jpg";
import image10 from "../assets/images/team/10.jpg";
import image11 from "../assets/images/team/11.jpg";

export default function Team() {
	return (
		<div>
			<div className="showcase-us mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:p-10">
				<div className="main-subtitle mb-4 text-lg"> Our core team</div>
				<h6 className="mb-10 text-3xl">Enigma Ekatva</h6>

				<div className="flex grid-coreteam flex-wrap w-100 justify-center gap-4 mx-auto text-xl">
					<div class="member-details md:w-1/4">
						<img src={image1}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Chairperson - Aryan Maurya
						</h6>
					</div>
				</div>

				<div className="flex grid-coreteam flex-wrap w-100 justify-center gap-4 mx-auto text-xl">
					<div class="member-details md:w-1/4">
						<img src={image10}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Secretary - Riya More
						</h6>
					</div>
				</div>

				<div className="md:flex grid-coreteam flex-wrap gap-4 w-100 justify-center mx-auto text-xl">
					<div class="member-details md:w-1/4">
						<img src={image2}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Treasurer - Vishwa Nadar
						</h6>
					</div>
					<div class="member-details md:w-1/4">
						<img src={image3}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Treasurer - Nisrga Kasar
						</h6>
					</div>
				</div>

				<div className="md:flex grid-coreteam flex-wrap gap-4 w-100 justify-center mx-auto text-xl">
					<div class="member-details md:w-1/4">
						<img src={image4}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Vice Chairperson - Sneha Tiwari
						</h6>
					</div>
					<div class="member-details md:w-1/4">
						<img src={image5}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Vice Chairperson - Bhushan Madgunde
						</h6>
					</div>
					<div class="member-details md:w-1/4">
						<img src={image6}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Vice Chairperson - Pavitra Mudaliar
						</h6>
					</div>
					<div class="member-details md:w-1/4">
						<img src={image7}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Vice Chairperson - Tia Gajjar
						</h6>
					</div>
					<div class="member-details md:w-1/4">
						<img src={image8}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Vice Chairperson - Pratham Chaughule
						</h6>
					</div>
					<div class="member-details md:w-1/4">
						<img src={image11}></img>
						<h6 className="memeber-info text-center font-bold p-4">
							Vice Chairperson - Soham Palkar
						</h6>
					</div>
				</div>
			</div>
		</div>
	);
}
