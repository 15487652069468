import React from 'react';
import "./social-banner.scss"

import facebookIcon from "../../assets/images/facebook.png";
import insta from "../../assets/images/instagram.png";
import gmail from "../../assets/images/gmail.png";
import pdf from "../../assets/images/pdf.png";
import pdfLink from "../../assets/pdf/pdf.pdf";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function Socialbanner() {

	const emailAddress = 'PODARENIGMAOFFICIAL23@GMAIL.COM';
	const subject = 'Regarding Events';

	const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;


	return (
		<div className='Socialbanner pt-2'>
			<div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:pt-10 text-center'>
				<AnimationOnScroll animateIn="animate__bounceIn"><h4 className="text-xl md:text-3xl">THE ESSENTIAL GUIDE TO UPCOMING FESTIVALS</h4></AnimationOnScroll>
				<div className='flex gap-8 text-center justify-center'>
					<div>
						<img src={facebookIcon}></img>
					</div>
					<div>
						<a href='https://www.instagram.com/podarenigmaofficial/?hl=en' target="_blank"><img src={insta}></img></a>
					</div>
					<div>
						<a href={mailtoLink}><img src={gmail}></img></a>
					</div>
					<div>
						<a href={pdfLink} target="_blank"><img src={pdf}></img></a>
					</div>

				</div>
				<p className='text-right -mt-3'>2024 Enigma Ekatva</p>
			</div>
		</div>
	)
}
