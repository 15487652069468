import React from "react";

import "./imagegrid.scss"

import image1 from "../../assets/images/events/1.jpg";
import image2 from "../../assets/images/events/2.jpg";
import image3 from "../../assets/images/events/3.jpg";
import image4 from "../../assets/images/events/4.jpg";
import image5 from "../../assets/images/events/5.jpg";
import image6 from "../../assets/images/events/6.jpg";
import image7 from "../../assets/images/events/7.jpg";
import image8 from "../../assets/images/events/8.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function ImageGrid() {
  return (
    <div>
      <div className="showcase-us mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 md:p-10">
        <div className="main-subtitle mb-4 text-lg">Previous Events</div>
        <AnimationOnScroll animateIn="animate__fadeInLeft"><h6 className="mb-10 text-3xl">Some Glimpses Of Our Previous Events</h6></AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__bounceIn">
          <div className="grid md:grid-cols-4 gap-4 text-xl">

            <div class="">
              <img src={image1}></img>
            </div>
            <div class="">
              <img src={image2}></img>
            </div>
            <div class="">
              <img src={image3}></img>
            </div>
            <div class="">
              <img src={image4}></img>
            </div>
            <div class="">
              <img src={image5}></img>
            </div>
            <div class="">
              <img src={image6}></img>
            </div>
            <div class="">
              <img src={image7}></img>
            </div>
            <div class="">
              <img src={image8}></img>
            </div>

          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
}
