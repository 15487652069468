import React, { useState, useEffect } from 'react';
import "./countdowntimer.scss"
const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className='count-timer-wrapper text-2xl md:text-3xl'>
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
        {/* <h6 className='mb-10 text-3xl'>Let the countdown begin</h6> */}

        <div class="count-down-timer grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="timer">
            <div className="time font-bold animate__animated animate__swing animate__infinite ">{days}</div>
            <div className="unit text-black">days</div>
          </div>
          <div className="timer">
            <div className="time font-bold animate__animated animate__swing animate__infinite ">{hours}</div>
            <div className="unit text-black">hours</div>
          </div>
          <div className="timer">
            <div className="time font-bold animate__animated animate__swing animate__infinite ">{minutes}</div>
            <div className="unit text-black">minutes</div>
          </div>
          <div className="timer">
            <div className="time font-bold animate__animated animate__swing animate__infinite ">{seconds}</div>
            <div className="unit text-black">seconds</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
